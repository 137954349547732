import React, { useEffect, useState } from 'react';
import { useCommonStatsv2, useAccountStatsv2 } from './hooks/useStats';
import { formatPrice, getContract } from '../../hooks/contractHelper';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import stakeAbi from '../../json/tokenstake.json';
import tokenAbi from '../../json/token.json';
import { formatDate, tokenAddressv2, tokenStakingAddressv2 } from '../../hooks/constant';
import { parseEther } from '@ethersproject/units';
import { CHAIN_ID, getWeb3 } from '../../hooks/connectors';
import Button from 'react-bootstrap-button-loader';
import Countdown from "react-countdown";

export default function TokenStakingv2() {
    const daysTab = {
        links: [
            {
                id: 1,
                className: "StakeTablinks",
                content: "30 Days",
                apy: "1",
                timestamp: "30"
            },
            {
                id: 2,
                className: "StakeTablinks",
                content: "90 Days",
                apy: "3",
                timestamp: "90"
            },
            {
                id: 3,
                className: "StakeTablinks",
                content: "180 Days",
                apy: "7",
                timestamp: "180"
            },
            {
                id: 4,
                className: "StakeTablinks",
                content: "365 Days",
                apy: "15",
                timestamp: "365"

            }
        ],
        activeLink: null
    };



    const { chainId, account, library } = useWeb3React();
    const [updater, setUpdater] = useState(1);
    const stats = useCommonStatsv2(updater);
    const accStats = useAccountStatsv2(updater);
    const [dayId, setDayId] = useState(1);
    const [apy, setApy] = useState(daysTab.links[0].apy);
    const [amount, setAmount] = useState(0);
    const [error_msg, setError_msg] = useState('');
    const [loading, setLoading] = useState(false);
    const [emloading, setEmloading] = useState(false);
    const [wloading, setWloading] = useState(false);
    const [btndisabled, setBtndisabled] = useState(false);

    const handleChangeDay = (id) => {
        setDayId(id);
        setApy(daysTab.links[id - 1].apy)
    }

    useEffect(() => {

        setInterval(() => {
            setUpdater(Math.random());
        }, 60000)
    }, []);


    const handleApprove = async (e) => {
        e.preventDefault();
        if (account) {
            if (chainId === CHAIN_ID) {
                try {
                    setLoading(true);
                    let tokenContract = getContract(tokenAbi, tokenAddressv2, library);
                    let amount = parseEther('10000000000000000000000000000000000000000').toString();
                    let tx = await tokenContract.approve(tokenStakingAddressv2, amount, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                        }
                    }, 5000);


                }
                catch (err) {
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
        setBtndisabled(true);

        if (isNaN(e.target.value)) {
            setError_msg('please enter valid amount');
            setBtndisabled(true);
        }

        else if (parseFloat(e.target.value) === 0 || e.target.value === '') {
            setError_msg('amount must be greater than zero');
            setBtndisabled(true);
        }
        else {
            setError_msg('');
            setBtndisabled(false);
        }
        return;
    }

    const handleMaxAmount = (e) => {
        e.preventDefault();
        if (parseFloat(accStats.balance) <= 0) {
            setAmount(0);
            setBtndisabled(true);
            setError_msg('amount must be greater than zero')
        }
        else {
            setAmount(Math.floor(accStats.balance));
            setBtndisabled(false);
            setError_msg('');
        }
    }

    const handleStake = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (amount > 0 && !isNaN(e.target.value)) {
                if (account) {
                    if (chainId === CHAIN_ID) {
                        if (parseFloat(accStats.balance) >= parseFloat(amount)) {
                            if (typeof dayId !== 'undefined' || dayId !== 0) {
                                let lockupDuration = daysTab.links[dayId - 1].timestamp;
                                let stakeContract = getContract(stakeAbi, tokenStakingAddressv2, library);
                                let stakeAmount = parseEther(amount.toString()).toString();
                                let tx = await stakeContract.deposit(stakeAmount, lockupDuration, { 'from': account });
                                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                                toast.promise(
                                    resolveAfter3Sec,
                                    {
                                        pending: 'Waiting for confirmation 👌',
                                    }
                                )

                                var interval = setInterval(async function () {
                                    let web3 = getWeb3();
                                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                                    if (response != null) {
                                        clearInterval(interval)
                                        if (response.status === true) {
                                            toast.success('success ! your last transaction is success');
                                            setUpdater(Math.random());
                                            setLoading(false);
                                        }
                                        else if (response.status === false) {
                                            toast.error('error ! Your last transaction is failed.');
                                            setUpdater(Math.random());
                                            setLoading(false);
                                        }
                                        else {
                                            toast.error('error ! something went wrong.');
                                            setUpdater(Math.random());
                                            setLoading(false);
                                        }
                                    }
                                }, 5000);

                            }
                            else {
                                toast.error('Please select days !');
                                setLoading(false);
                            }
                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    const handleWithdraw = async (e) => {
        e.preventDefault();
        setWloading(true);
        try {
            if (accStats.pendingWithdraw > 0) {
                if (account) {
                    if (chainId === CHAIN_ID) {
                        let stakeContract = getContract(stakeAbi, tokenStakingAddressv2, library);
                        let tx = await stakeContract.withdraw({ 'from': account });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            let web3 = getWeb3();
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success');
                                    setUpdater(Math.random());
                                    setWloading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setWloading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setWloading(false);
                                }
                            }
                        }, 5000);
                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setWloading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setWloading(false);
                }
            }
            else {
                toast.error('withdrawal amount must be greater than zero!');
                setWloading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWloading(false);
        }
    }

    const handleEmergencyWithdraw = async (e) => {
        e.preventDefault();
        setEmloading(true);
        try {
            if (account) {
                if (accStats.totalStake > 0) {
                    if (chainId === CHAIN_ID) {
                        let stakeContract = getContract(stakeAbi, tokenStakingAddressv2, library);
                        let tx = await stakeContract.emergencyWithdraw({ 'from': account });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            let web3 = getWeb3();
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success');
                                    setUpdater(Math.random());
                                    setEmloading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setEmloading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setEmloading(false);
                                }
                            }
                        }, 5000);
                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setEmloading(false);
                    }
                }
                else {
                    toast.error('withdrawal amount must be greater than zero!');
                    setEmloading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setEmloading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setEmloading(false);
        }
    }

    const setCountdown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <ul>
                    <li>0<span>D</span></li>
                    <li>0<span>H</span></li>
                    <li>0<span>M</span></li>
                    <li>0<span>S</span></li>
                </ul>
            );
        }
        else {
            return (
                <ul>
                    <li>{days}<span>D</span></li>
                    <li>{hours}<span>H</span></li>
                    <li>{minutes}<span>M</span></li>
                    <li>{seconds}<span>S</span></li>
                </ul>
            );
        }
    }

    const handleTimerEnd = () => {
        setUpdater(Math.random());
    }

    return (
        <React.Fragment>
            {/* Breadcrumbs Section Start --> */}
            <div className="gamfi-breadcrumbs-section ProjectClasicBreadCumSect governance_breadcrumbs_scetion">
                <div className="governance_breadcrumbs_content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="breadcrumbs-area sec-heading">
                                    <h2 className="title mb-0 blue-color">Stake Tokens </h2>
                                </div>
                            </div>
                            <div className="col-lg-7 breadcrumbs-form md-pt-30">
                                <div className="staking_bradcome_right">
                                    <ul>
                                        <li>
                                            <h6>Total Stake</h6>
                                            <h5><span className="counter1">{formatPrice(stats.totalStake)}</span> wBST</h5>
                                            {/* <h6>${formatPrice(stats.totalStake * stats.bstPrice)}</h6> */}
                                        </li>
                                        <li>
                                            <h6>APY</h6>
                                            <h5><span className="counter">15</span>% (UPTO)</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs Section End --> */}

            {/* Participat Detaisl Content Start --> */}
            <div className="participat-information project-details-conent gamfi-about-secion pb-80 md-pb-50">
                <div className="container">
                    <div className="row pt-0">
                        <div className="col-lg-6 pr-25 md-pr-15">
                            <div className="project-item">
                                <div className="project-info m-0">
                                    <h4 className="mb-56">Participate Stake</h4>
                                    {accStats.nextRewardTime > 0 &&
                                        <>
                                            <span>Next Reward Earned In</span>
                                            <div className="staking4_time_counter">
                                                <div className="price-counter">
                                                    <div className="timer timer_1">
                                                        <Countdown onComplete={handleTimerEnd} key={Math.floor((Math.random() * 10) + 1)} date={accStats.nextRewardTime} renderer={setCountdown} />

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <ul className="date-listing mb-35" id="staking_preiod_btn" key="uniwsdhb">
                                    {daysTab.links.map(link => {
                                        return (
                                            <>
                                                <li key={link.id} onClick={(e) => { handleChangeDay(link.id) }} className={link.className + (link.id === dayId ? " active" : "")} id="defaultOpen">
                                                    <button key={link.id} >{link.content}</button>
                                                </li>

                                            </>
                                        )
                                    })
                                    }
                                </ul>
                                <div className="project-content">
                                    <div id="sevenDays" className="StakeTabcontent">
                                        <div className="project-media mb-40">
                                            <ul className="project-listing">
                                                <li><span>APY Rate</span></li>
                                                <li><strong></strong> <a href="#sec"><span className="big-text">{apy} %</span></a></li>
                                                <li>Early unstake fee: <strong>25%</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-form-list">
                                    <h4>Amount to Stake</h4>
                                    <div className="balance-form-area mb-1">
                                        <input type="text" value={amount} onChange={(e) => { handleChangeAmount(e) }} placeholder="Enter Amount" />
                                        <button type='button' className="max" onClick={handleMaxAmount}>MAX</button>

                                        {parseFloat(accStats.isApprove) < parseFloat(amount) ? (
                                            <div className="white-shape-small approve" onClick={(e) => handleApprove(e)}>
                                                <Button loading={loading} type="button">APPROVE</Button>
                                                <span className="hover-shape1"></span>
                                                <span className="hover-shape2"></span>
                                                <span className="hover-shape3"></span>
                                            </div>
                                        ) : (
                                            <div className="white-shape-small approve">
                                                <Button disabled={btndisabled} loading={loading} type="button" onClick={handleStake} >STAKE</Button>
                                                <span className="hover-shape1"></span>
                                                <span className="hover-shape2"></span>
                                                <span className="hover-shape3"></span>
                                            </div>
                                        )}

                                    </div>
                                    <h5 className='text-danger mb-47'><small>{error_msg}</small></h5>

                                    <h6 className="mb-9">Balance:
                                        <span>{formatPrice(accStats.balance)} wBST</span>
                                        <a target="_blank" rel="noreferrer" href="https://launchpad.blockstar.site/swap/">Buy Now
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-25 md-pl-15">
                            <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                                <div className="project-value">
                                    <h3 className="mb-15">{formatPrice(accStats.totalStake)} wBST</h3>
                                    <h5 className="mb-15">${formatPrice(accStats.totalStake * stats.bstPrice)}</h5>
                                    <h6>Staked Amount</h6>
                                </div>
                                <div className="project-value-image">
                                    <Button loading={emloading} type="button" className="hover-shape" onClick={handleEmergencyWithdraw}>
                                        Emergency Withdraw
                                        <span className="hover-shape1"></span>
                                        <span className="hover-shape2"></span>
                                        <span className="hover-shape3"></span>
                                    </Button>
                                </div>
                            </div>
                            <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                                <div className="project-value">
                                    <h3 className="mb-15">{formatPrice(accStats.pendingWithdraw)} wBST</h3>
                                    <h6>Available Withdraw</h6>
                                </div>
                                <div className="project-value-image">
                                    <Button disabled={(accStats.pendingWithdraw > 0) ? false : true} loading={wloading} type="button" className="hover-shape" onClick={handleWithdraw}>
                                        Withdraw
                                        <span className="hover-shape1"></span>
                                        <span className="hover-shape2"></span>
                                        <span className="hover-shape3"></span>
                                    </Button>
                                </div>
                            </div>
                            <div className="project-item project-value-inner d-flex justify-content-between align-items-center">
                                <div className="project-value">
                                    <h3 className="mb-15">{formatPrice(accStats.pendingRewards)} wBST</h3>
                                    <h6>Rewards Amount</h6>
                                </div>
                            </div>
                            <div className="project-item project-value-inner d-flex justify-content-between align-items-center">
                                <div className="project-value">
                                    <h3 className="mb-15">{formatPrice(accStats.rewardEarned)} wBST</h3>
                                    <h6>Rewards Earned</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Participat Detaisl Content End --> */}


            <div className="staking4_table_sect pb-140">
                <div className="container">
                    <div className="ProjectDetails2_AllocationsTableSect m-0">
                        <h2 className='blue-color'>Stake History<span><img src="assets/images/icons/steps.png" alt="" className="img-fluid" /></span></h2>
                        <div className="ProjectDetails2_AllocationsTable">
                            <div className="ProjectDetails2_AllocationsTableHeadings">
                                <ul>
                                    <li className="table_col1">No.</li>
                                    <li className="table_col4">Amount</li>
                                    <li className="table_col2">Start Time</li>
                                    <li className="table_col3">End Time</li>
                                    <li className="table_col4">Status</li>
                                </ul>
                            </div>

                            <div className="ProjectDetails2_AllocationsTableBody">
                                {accStats.depositehistory.length > 0 ? (accStats.depositehistory.map((result, key) => {
                                    return (
                                        <ul key={key}>
                                            <li className="table_col1">{key + 1}</li>
                                            <li className="table_col4">{formatPrice(result[0].toString() / Math.pow(10, 18))} wBST</li>
                                            <li className="table_col2">{formatDate(result[3].toString() * 1000)}</li>
                                            <li className="table_col3">{formatDate(result[4].toString() * 1000)}</li>
                                            <li className="table_col4">{result[5].toString() === 'true' ? <span className='text-success'>Clamied</span> : <span className='text-warning'>Pending</span>}</li>
                                        </ul>
                                    )
                                })) : (
                                    <div className='text-center mt-5 mb-5'>No Record Found</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
