import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
    NoEthereumProviderError
} from "@web3-react/injected-connector";

import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet } from "../hooks/connectors";
import Modal from 'react-bootstrap/Modal';
import { CHAIN_ID } from "../hooks/connectors";
import { trimAddress } from '../hooks/constant';



export const MobileConnect = function () {
    const context = useWeb3React();
    const { connector, chainId, account, activate, deactivate, active, error } = context;
    const [show, setShow] = useState(false);

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);


    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            return "Metamask not deteced";
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={switchNetwork}>Switch Network</span>;
        }

        deactivate(injected);
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = () => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <li>

                {
                    error &&
                    <button type="button" className="readon black-shape-big connectWalletBtnforMobile" onClick={() => {
                        setActivatingConnector(injected);
                    }}>
                        <span className="btn-text">{getErrorMessage(error)}</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                    </button>
                }
                {!error &&
                    <>
                        {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                            <button type="button" className="readon black-shape-big connectWalletBtnforMobile">
                                {CHAIN_ID === chainId ? (
                                    <span className="btn-text" onClick={() => {
                                        setActivatingConnector();
                                        deactivate(injected);
                                        deactivate(walletconnect);
                                        deactivate(coinbaseWallet);

                                    }} >{account && trimAddress(account)}</span>
                                ) : (
                                    <span className="btn-text" onClick={switchNetwork}>Switch Network</span>
                                )}

                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </button>


                        }
                        { !active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                            <button type="button" className="readon black-shape-big connectWalletBtnforMobile" onClick={() => {
                                setShow(!show);
                            }}>
                                <img src="assets/images/icons/connect.png" alt="Icon" />
                                {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="btn-text">Connecting...</span>}
                                {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="btn-text">Connect wallet</span>}
                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </button>
                        }
                    </>
                }
            </li>

            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body>
                    <p className="mb-20">Please select a wallet to connect to this marketplace</p>
                    <div className="connect-section">
                        <ul className="heading-list">
                            <li onClick={() => {
                                activate(injected);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src="assets/images/icons/meta-mask.png" alt="Meta-mask-Im" /></span>MetaMask
                                </button>
                            </li>
                            <li onClick={() => {
                                activate(coinbaseWallet);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src="assets/images/icons/coinbase.png" alt="Coinbase-Im" /></span>Coinbase
                                </button>
                            </li>
                            <li onClick={() => {
                                activate(injected);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src="assets/images/icons/trust.png" alt="Trust-Ima" /></span>Trust Wallet
                                </button>
                            </li>
                            <li onClick={() => {
                                activate(walletconnect);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src="assets/images/icons/wallet.png" alt="Wallet-Im" /></span>WalletConnect
                                </button>
                            </li>
                        </ul>
                    </div>
                    <p>By connecting your wallet, you agree to our <a href="#sec">
                        <span className="modal-title">Terms of Service </span></a>and our <a href="#sec">
                            <span className="modal-title"> Privacy Policy</span></a>.</p>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default MobileConnect;