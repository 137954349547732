import React, { useEffect, useState } from 'react';
import { useAccountStats } from './hooks/useStats';
import axios from 'axios';
import { formatPrice } from '../../hooks/contractHelper';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { nftstakingAddress } from '../../hooks/constant';
import stakenftAbi from '../../json/stakeNft.json';
import { getContract } from '../../hooks/contractHelper';
import Button from 'react-bootstrap-button-loader';
import { CheckApprove } from './hooks/useStats';
import nftAbi from '../../json/nft.json';


export default function NftStaking() {
    const [updater, setUpdater] = useState(1);
    const context = useWeb3React();
    const { chainId, account, library } = context;
    const accStats = useAccountStats(updater);
    const [metadata, setMetadata] = useState([]);
    const [stakedNft, setStakedNft] = useState([]);
    const [stakebtn, setStakebtn] = useState(true);
    const [stakeloading, setStakeLoading] = useState(false);
    const [unstakebtn, setUnstakebtn] = useState(true);
    const [unstakeloading, setUnstakeLoading] = useState(false);
    const [approveloading, setApproveLoading] = useState(false);
    const [claimloading, setClaimloading] = useState(false);
    const [checkedState, setCheckedState] = useState(
        new Array(metadata.length).fill(false)
    );
    const [stakedState, setStakedState] = useState(
        new Array(stakedNft.length).fill(false)
    );

    const [totalshare,setTotalshare] = useState(0);

    const [approvenft, setApprovenft] = useState([]);

    useEffect(() => {

        async function axiosdatafetch(uri) {
            return Promise.all(uri.map(async (item, key) => {
                let result = item.replace("https://gateway.pinata.cloud/ipfs/", "https://ipfs.io/ipfs/");
                let fetchdata = await axios.get(result);
                if (fetchdata.status === 200) {
                    return { name: fetchdata.data.name, image: fetchdata.data.image.slice(7) };
                }
            }));
        }


        async function fecthUrl() {
            if (accStats.uri) {
                let data = axiosdatafetch(accStats.uri);
                data.then((result) => {
                    setMetadata(result);
                    setCheckedState(new Array(result.length).fill(false));
                })
            }
        }

        async function fecthstakeUrl() {
            if (accStats.stake_uri) {
                let data = axiosdatafetch(accStats.stake_uri);
                data.then((result) => {
                    setStakedNft(result);
                    setStakedState(new Array(result.length).fill(false))
                })
            }
        }

        fecthUrl();
        fecthstakeUrl();
    }, [accStats, account]);


    useEffect(() => {
        if (account) {
            let checkAprroval = CheckApprove(accStats.nftAddress, account);
            checkAprroval.then((result) => {
                setApprovenft(result);
            });
        }
    }, [account, accStats])


    const handleOnChange = (position) => {

        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        const checkIsselected = updatedCheckedState.indexOf(true) !== -1 ? false : true;
        const checkIsApprove = approvenft.indexOf((value) => value.approve === false) !== -1 ? true : false;

        if (checkIsApprove) {
            setStakebtn(checkIsApprove);
        }
        else if (checkIsselected) {
            setStakebtn(checkIsselected);
        }
        else {
            setStakebtn(false);
        }


        setCheckedState(updatedCheckedState);
    }

    const handleStakedOnChange = (position) => {

        const updatedCheckedState = stakedState.map((item, index) =>
            index === position ? !item : item
        );

        const checkIsselected = updatedCheckedState.indexOf(true) !== -1 ? false : true;
        setUnstakebtn(checkIsselected);
        setStakedState(updatedCheckedState);
    }

    const stakeNft = async (e) => {
        e.preventDefault();

        setStakeLoading(true);
        if (account) {
            if (chainId === 56) {
                if (checkedState.indexOf(true) !== -1) {
                    try {
                        let stakeAddress = [];
                        let stakeTokenId = [];
                        let i = 0;
                        checkedState.map((item, index) => {
                            if (item) {
                                stakeAddress[i] = accStats.nftAddress[index]
                                stakeTokenId[i] = accStats.tokenId[index]
                                i++;
                            }
                            return index;
                        });

                        let stakeContract = getContract(stakenftAbi, nftstakingAddress, library);
                        let tx = await stakeContract.stakeBatchNFT(stakeAddress, stakeTokenId, { 'from': account });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 15000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )
                        let response = await tx.wait();


                        if (response) {
                            if (response.status === 1) {
                                toast.success('success ! your last transaction is success👍');
                                setUpdater(Math.random());
                                setStakeLoading(false);
                            }
                            else if (response.status === 0) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setStakeLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setStakeLoading(false);
                            }
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(Math.random());
                            setStakeLoading(false);
                        }
                    }
                    catch (err) {
                        typeof err.reason !== 'undefined' ? toast.error(err.reason) : toast.error(err.message);
                        setStakeLoading(false);
                    }

                }
                else {
                    toast.error('please select Nft !');
                    setStakeLoading(false);
                }
            }
            else {
                toast.error('Please switch network to Bsc smartchain !');
                setStakeLoading(false);
            }
        }
        else {
            toast.error('Please connect wallet !');
            setStakeLoading(false);
        }

    }


    const handleApproveNft = async (nftAddress) => {
        setApproveLoading(true);
        if (account) {
            if (chainId === 56) {
                try {
                    let nftContract = getContract(nftAbi, nftAddress, library);
                    let tx = await nftContract.setApprovalForAll(nftstakingAddress, true, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 15000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )
                    let response = await tx.wait();


                    if (response) {
                        if (response.status === 1) {
                            toast.success('success ! your last transaction is success👍');
                            setUpdater(Math.random());
                            setApproveLoading(false);
                        }
                        else if (response.status === 0) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(Math.random());
                            setApproveLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(Math.random());
                            setApproveLoading(false);
                        }
                    }
                    else {
                        toast.error('error ! something went wrong.');
                        setUpdater(Math.random());
                        setApproveLoading(false);
                    }
                }
                catch (err) {
                    typeof err.reason !== 'undefined' ? toast.error(err.reason) : toast.error(err.message);
                    setApproveLoading(false);
                }
            }
            else {
                toast.error('Please switch network to Bsc smartchain !');
                setApproveLoading(false);
            }
        }
        else {
            toast.error('Please connect wallet !');
            setApproveLoading(false);
        }
    }

    const handleUnstakeNft = async (e) => {
        e.preventDefault();
        setUnstakeLoading(true);
        if (account) {
            if (chainId === 56) {
                if (stakedState.indexOf(true) !== -1) {
                    try {
                        let stakeAddress = [];
                        let stakeTokenId = [];
                        let i = 0;
                        stakedState.map((item, index) => {
                            if (item) {
                                stakeAddress[i] = accStats.stake_nftAddress[index]
                                stakeTokenId[i] = accStats.stake_tokenId[index]
                                i++;
                            }
                            return index;
                        });

                        console.log(stakeAddress);
                        console.log(stakeTokenId);
                        let stakeContract = getContract(stakenftAbi, nftstakingAddress, library);
                        let tx = await stakeContract.unStakeBatchNFT(stakeAddress, stakeTokenId, { 'from': account });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 15000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )
                        let response = await tx.wait();


                        if (response) {
                            if (response.status === 1) {
                                toast.success('success ! your last transaction is success👍');
                                setUpdater(Math.random());
                                setUnstakeLoading(false);
                            }
                            else if (response.status === 0) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setUnstakeLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setUnstakeLoading(false);
                            }
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(Math.random());
                            setUnstakeLoading(false);
                        }
                    }
                    catch (err) {
                        typeof err.reason !== 'undefined' ? toast.error(err.reason) : toast.error(err.message);
                        setUnstakeLoading(false);
                    }

                }
                else {
                    toast.error('please select Nft !');
                    setUnstakeLoading(false);
                }
            }
            else {
                toast.error('Please switch network to Bsc smartchain !');
                setUnstakeLoading(false);
            }
        }
        else {
            toast.error('Please connect wallet !');
            setUnstakeLoading(false);
        }

    }

    const handleClaimReward = async (e) => {
        e.preventDefault();
        setClaimloading(true);
        if (account) {
            if (chainId === 56) {
                if (accStats.totalRewardAvalible > 0) {
                    try {
                        let stakeContract = getContract(stakenftAbi, nftstakingAddress, library);
                        let tx = await stakeContract.claimReward({ 'from': account });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 15000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )
                        let response = await tx.wait();


                        if (response) {
                            if (response.status === 1) {
                                toast.success('success ! your last transaction is success👍');
                                setUpdater(Math.random());
                                setClaimloading(false);
                            }
                            else if (response.status === 0) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setClaimloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setClaimloading(false);
                            }
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(Math.random());
                            setClaimloading(false);
                        }
                    }
                    catch (err) {
                        typeof err.reason !== 'undefined' ? toast.error(err.reason) : toast.error(err.message);
                        setClaimloading(false);
                    }
                }
                else {
                    toast.error('Oops ! no reward avalible for claim.');
                    setClaimloading(false);
                }
            }
            else {
                toast.error('Please switch network to Bsc smartchain !');
                setClaimloading(false);
            }
        }
        else {
            toast.error('Please connect wallet !');
            setClaimloading(false);
        }
    }

    useEffect(()=>{
        const initialValue = 0;
        let sumWithInitial = accStats.stake_rType.reduce(
            (previousValue, currentValue) => previousValue  + (currentValue / 10) , initialValue);
            setTotalshare(sumWithInitial);
    },[accStats]);




    return (
        <React.Fragment>
            {/* Breadcrumbs Section Start --> */}
            <div className="gamfi-breadcrumbs-section ProjectClasicBreadCumSect governance_breadcrumbs_scetion">
                <div className="governance_breadcrumbs_content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="breadcrumbs-area sec-heading">
                                    <h2 className="title mb-0 blue-color">Wallet NFTS</h2>
                                    <a target="_blank" rel="noreferrer"  className="btn btn-primary mt-3" href='https://nft.blockstar.site/view-collection/MQ'>
                                    <span className="btn-text">Purchase NFTs</span>
                                    <span className="hover-shape1"></span>
                                    <span className="hover-shape2"></span>
                                    <span className="hover-shape3"></span>
                                </a>
                                </div>
                            </div>
                            <div className="col-lg-7 breadcrumbs-form md-pt-30">
                                <div className="staking_bradcome_right">
                                    <ul>
                                        <li>
                                            <h6>Total Share</h6>
                                            <h5><span className="counter">{formatPrice(totalshare)}
                                            </span></h5>
                                        </li>
                                        <li>
                                            <h6>Claim Reward</h6>
                                            <h5><span className="counter">{formatPrice(accStats.totalRewardAvalible)}</span> BST</h5>
                                        </li>
                                        <li>
                                            <h6>Rewards Earned</h6>
                                            <h5><span className="counter">{formatPrice(accStats.UserRewardEarned)}</span> BST</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs Section End --> */}

            {/* Participat Detaisl Content Start --> */}
            <div className="participat-information project-details-conent gamfi-about-secion pb-80 md-pb-50">
                <div className="container">
                    <div className="row pt-0">
                        <div className="col-lg-12 pr-25 md-pr-15">
                            <div className="container p-5">

                                <div className="css-p1nhy6">
                                    <h3 className='mb-0 blue-color mt-3' style={{ "display": "inline" }}>Staked NFT's</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                                        <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"></path>
                                    </svg><span>(Information)</span>
                                    <div className="rarity-legend css-1e07v95">
                                        <div className="css-7ccrkh">
                                            <div className="attr-value">common</div>
                                            <div className="attr-multiplier">0.2 share</div>
                                        </div>
                                        <div className="css-1rp048y">
                                            <div className="attr-value">uncommon</div>
                                            <div className="attr-multiplier">0.4 share</div>
                                        </div>
                                        <div className="css-1yo9alp">
                                            <div className="attr-value">rare</div>
                                            <div className="attr-multiplier">0.6 share</div>
                                        </div>
                                        <div className="css-1kbvg52">
                                            <div className="attr-value">epic</div>
                                            <div className="attr-multiplier">1 share</div>
                                        </div>
                                    </div>
                                </div>



                                <Button loading={unstakeloading} className="black-shape readon" disabled={unstakebtn} onClick={(e) => handleUnstakeNft(e)}>
                                    <span className="btn-text">UnStake Selected</span>
                                    <span className="hover-shape1"></span>
                                    <span className="hover-shape2"></span>
                                    <span className="hover-shape3"></span>
                                </Button>
                                {accStats.totalRewardAvalible > 0 &&
                                    <Button loading={claimloading} className="black-shape readon" onClick={(e) => handleClaimReward(e)}>
                                        <span className="btn-text">Claim Reward</span>
                                        <span className="hover-shape1"></span>
                                        <span className="hover-shape2"></span>
                                        <span className="hover-shape3"></span>
                                    </Button>
                                }



                                {stakedNft.length > 0 ? (

                                    <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3 mt-3 mb-5">

                                        {
                                            stakedNft.map((rowdata, keys) => {
                                                let image_url = `https://ipfs.io/ipfs/${rowdata.image}`;
                                                return (
                                                    <div className="css-1a24g11" key={keys}>
                                                        <div className="nft-image" onClick={() => handleStakedOnChange(keys)}>
                                                            <input name={keys}
                                                                type="checkbox"
                                                                value={keys}
                                                                checked={stakedState[keys]}
                                                                onChange={(e) => { e.preventDefault() }}
                                                            />
                                                            <label htmlFor={rowdata.name}></label>
                                                            <img src={image_url} alt={image_url} />
                                                        </div>
                                                        <div className="nft-description">
                                                            <div className="description-wrapper">
                                                                <h4 className="css-1firr18 mt-1">{rowdata.name}</h4>
                                                                <div className="css-1nvmyzo mt-1">Type : {
                                                                    (accStats.stake_rType[keys] === '2') ? "Common" :
                                                                        (accStats.stake_rType[keys] === '4') ? "UnCommon" :
                                                                            (accStats.stake_rType[keys] === '6') ? "Rare" :
                                                                                (accStats.stake_rType[keys] === '10') ? "Epic" :
                                                                                "Common"

                                                                }</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : (
                                    <div className="row g-3 text-center mt-3">
                                        <h5 className="text-center">
                                            <img src="../assets/images/empty.png" alt="empty" />
                                            <br />
                                            No items found
                                        </h5>
                                    </div>
                                )}


                                <h3 className="title mb-0 blue-color mt-3">Available NFT's</h3>
                                <Button loading={stakeloading} className="black-shape readon" disabled={stakebtn} onClick={(e) => stakeNft(e)}>
                                    <span className="btn-text">Stake Selected</span>
                                    <span className="hover-shape1"></span>
                                    <span className="hover-shape2"></span>
                                    <span className="hover-shape3"></span>
                                </Button>

                                {approvenft.length > 0 && approvenft.map((value, index) => {
                                    if (value.approve === false)
                                        return (
                                            <Button key={index} loading={approveloading} className="black-shape readon" onClick={(e) => handleApproveNft(value.nftAddress)}>
                                                <span className="btn-text">Approve</span>
                                                <span className="hover-shape1"></span>
                                                <span className="hover-shape2"></span>
                                                <span className="hover-shape3"></span>
                                            </Button>
                                        )

                                    else
                                        return (
                                            <></>
                                        )

                                }
                                )
                                }



                                {metadata.length > 0 ? (

                                    <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3 mt-3">

                                        {
                                            metadata.map((rowdata, keys) => {
                                                let image_url = `https://ipfs.io/ipfs/${rowdata.image}`;
                                                return (
                                                    <div className="css-1a24g11" key={keys}>
                                                        <div className="nft-image" onClick={() => handleOnChange(keys)}>
                                                            <input name={keys}
                                                                type="checkbox"
                                                                value={keys}
                                                                checked={checkedState[keys]}
                                                                onChange={(e) => { e.preventDefault() }}
                                                            />
                                                            <label htmlFor={rowdata.name}></label>
                                                            <img src={image_url} alt={image_url} />
                                                        </div>
                                                        <div className="nft-description">
                                                            <div className="description-wrapper">
                                                                <h4 className="css-1firr18 mt-1">{rowdata.name}</h4>
                                                                <div className="css-1nvmyzo mt-1">Type : {
                                                                    (accStats.rType[keys] === '2') ? "Common" :
                                                                        (accStats.rType[keys] === '4') ? "UnCommon" :
                                                                            (accStats.rType[keys] === '6') ? "Rare" :
                                                                                (accStats.rType[keys] === '10') ? "Epic" :
                                                                                "Common"

                                                                }</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : (
                                    <div className="row g-3 text-center mt-3">
                                        <h5 className="text-center">
                                            <img src="../assets/images/empty.png" alt="empty" />
                                            <br />
                                            No items found
                                        </h5>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
