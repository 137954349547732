import React from 'react';
import { Link } from "react-router-dom"
import Connect from './Connect';
import MobileConnect from './MobileConnect';

export default function Header() {

    const toggleNav = () => {
        document.body.classList.add('nav-expanded');
    }

    const toggleNavClose = () => {
        document.body.classList.remove('nav-expanded');
    }


    return (
        <React.Fragment>

            {/* <section className="loader_first">
                <div className="circular-spinner"></div>
            </section> */}
            {/* Preloader area End here */}

            {/* Header Start */}
            <header id="gamfi-header" className="gamfi-header-section transparent-header">
                <div className="menu-area menu-sticky">
                    <div className="container">
                        <div className="heaader-inner-area d-flex justify-content-between align-items-center">
                            <div className="gamfi-logo-area d-flex justify-content-between align-items-center">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="assets/images/default_images/Untitled11.png" alt="logo" style={{ "height": "50px", "width": "90%" }} />
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="nav-menu">
                                        <li><Link to="/">Home</Link></li>
                                        <li><a href="#sec">Token Stake</a>
                                            <ul className="sub-menu">
                                                <li><Link to="/tokenStakingv2">Blockstar (BST)</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/nftStaking">NFT Stake</Link></li>
                                        {/* <li><Link to="/lpstaking">LP Stake</Link></li> */}
                                        <li>
                                            <a href="https://blockstar.site/portfolio" target="_blank" rel="noreferrer">Portfolio</a>
                                        </li>
                                        <li><a target="_blank" rel="noreferrer" href=" https://launchpad.blockstar.site/swap/">Swap</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="gamfi-btn-area">
                                <ul>
                                    <li>
                                        <a href="#sec" id="nav-expander" onClick={(e) => toggleNav(e)} className="nav-expander bar">
                                            <div className="bar">
                                                <span className="dot1"></span>
                                                <span className="dot2"></span>
                                                <span className="dot3"></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="buy-token">
                                        <a className="readon black-shape" href="#sec">
                                            <span className="btn-text">Buy Token </span>
                                            <i className="icon-arrow_down"></i>
                                            <span className="hover-shape1"></span>
                                            <span className="hover-shape2"></span>
                                            <span className="hover-shape3"></span>
                                        </a>
                                        <ul>
                                            <li>
                                                <a target="_blank" rel="noreferrer" href="https://launchpad.blockstar.site/swap/">
                                                    <img src="assets/images/default_images/Untitled1.png" width="30px" height="30px" alt="blockstar" /> BlockStar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <Connect />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Canvas Mobile Menu start  */}
                <nav className="right_menu_togle mobile-navbar-menu" id="mobile-navbar-menu">
                    <div className="close-btn">
                        <a href='#sec' onClick={(e) => toggleNavClose(e)} className="nav-close">
                            <div className="line">
                                <span className="line1"></span>
                                <span className="line2"></span>
                            </div>
                        </a>
                    </div>
                    <div className="sidebar-logo mb-30">
                        <Link to="/"><img src="assets/images/default_images/Untitled11.png" alt="logo-dark" style={{ "height": "60px" }} /></Link>
                    </div>
                    <ul className="nav-menu">
                        <ul className="nav-menu">
                            <li><Link to="/">Home</Link></li>
                            <li><a href="#sec">Token Stake</a>
                                <ul className="sub-menu">
                                    <li><Link to="/tokenStakingv2">Blockstar (BST)</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/nftStaking">NFT Stake</Link></li>
                            {/* <li><Link to="/lpstaking">LP Stake</Link></li> */}
                            <li>
                                <a href="https://blockstar.site/portfolio" target="_blank" rel="noreferrer">Portfolio</a>
                            </li>
                            <li><a target="_blank" rel="noreferrer" href=" https://launchpad.blockstar.site/swap/">Swap</a></li>
                            <li><a href="#sec">Buy Token</a>
                                <ul className="sub-menu">
                                    <li>
                                        <a target="_blank" rel="noreferrer" href="https://launchpad.blockstar.site/swap/">
                                            <img src="assets/images/default_images/Untitled1.png" width="30px" height="30px" alt="blockstar" /> BlockStar
                                        </a>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                        <MobileConnect />
                    </ul>
                </nav>

            </header>

        </React.Fragment>
    )
}
