import { useEffect, useState } from "react"
import { MulticallContract } from "../../../hooks/useContracts";
import { stakeItf, tokenItf, lpItf } from "../../../json/interface";
import { tokenStakingAddressv2, tokenAddressv2, BST_LP_ADDRESS, WETH_USD_LP_ADDRESS } from '../../../hooks/constant';
import { CHAIN_ID } from "../../../hooks/connectors";
import { useWeb3React } from '@web3-react/core';



// const _1e5 = BigNumber.from(10).pow(YLF_DECIMAL);

const calls = [
  [tokenStakingAddressv2, stakeItf.encodeFunctionData('totalRewardsDistribution')],
  [tokenStakingAddressv2, stakeItf.encodeFunctionData('totalStake')],
  [tokenStakingAddressv2, stakeItf.encodeFunctionData('totalWithdrawal')]
  // [BST_LP_ADDRESS, lpItf.encodeFunctionData('getReserves')], //3
  // [WETH_USD_LP_ADDRESS, lpItf.encodeFunctionData('getReserves')], //4
];

export const useCommonStatsv2 = (update) => {
  const context = useWeb3React();
  const { chainId } = context;
  const [stats, setStats] = useState({
    totalRewardsDistribution: 0,
    totalStake: 0,
    totalWithdrawal: 0,
    ethPrice: 0,
    bstPrice: 0

  });

  const mc = MulticallContract(1);

  console.log(mc)

  useEffect(() => {
    const fetch = async () => {
      try {
        const [, data] = await mc.aggregate(calls);

        let eth_price = 0; //(lpItf.decodeFunctionResult('getReserves', data[4])[1].toString() / lpItf.decodeFunctionResult('getReserves', data[4])[0].toString()) * Math.pow(10, 12);
        let bst_price = 0; //eth_price / (lpItf.decodeFunctionResult('getReserves', data[3])[0].toString() / lpItf.decodeFunctionResult('getReserves', data[3])[1].toString());

        setStats({
          totalRewardsDistribution: stakeItf.decodeFunctionResult('totalRewardsDistribution', data[0])[0].toString() / Math.pow(10, 18),
          totalStake: stakeItf.decodeFunctionResult('totalStake', data[1])[0].toString() / Math.pow(10, 18),
          totalWithdrawal: stakeItf.decodeFunctionResult('totalWithdrawal', data[2])[0].toString() / Math.pow(10, 18),
          ethPrice: eth_price,
          bstPrice: bst_price
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStatsv2 = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const [stats, setStats] = useState({
    balance: 0,
    rewardEarned: 0,
    pendingRewards: 0,
    totalStake: 0,
    isApprove: 0,
    pendingWithdraw: 0,
    depositehistory: [],
    nextRewardTime: 0

  });

  const mc = MulticallContract();

  useEffect(() => {
    const fetch = async () => {
      const calls = [
        [tokenAddressv2, tokenItf.encodeFunctionData('balanceOf', [account])],
        [tokenStakingAddressv2, stakeItf.encodeFunctionData('rewardEarned', [account])],
        [tokenStakingAddressv2, stakeItf.encodeFunctionData('pendingRewards', [account])],
        [tokenStakingAddressv2, stakeItf.encodeFunctionData('userBalance', [account])],
        [tokenAddressv2, tokenItf.encodeFunctionData('allowance', [account, tokenStakingAddressv2])],
        [tokenStakingAddressv2, stakeItf.encodeFunctionData('pendingWithdraw', [account])],
        [tokenStakingAddressv2, stakeItf.encodeFunctionData('getUserDeposite', [account])],
        [tokenStakingAddressv2, stakeItf.encodeFunctionData('getNextRewardTime', [account])],

      ];


      const [, data] = await mc.aggregate(calls);





      setStats({
        balance: tokenItf.decodeFunctionResult('balanceOf', data[0])[0].toString() / Math.pow(10, 18),
        rewardEarned: stakeItf.decodeFunctionResult('rewardEarned', data[1])[0].toString() / Math.pow(10, 18),
        pendingRewards: stakeItf.decodeFunctionResult('pendingRewards', data[2])[0].toString() / Math.pow(10, 18),
        totalStake: stakeItf.decodeFunctionResult('userBalance', data[3])[0].toString() / Math.pow(10, 18),
        isApprove: tokenItf.decodeFunctionResult('allowance', data[4])[0].toString() / Math.pow(10, 18),
        pendingWithdraw: stakeItf.decodeFunctionResult('pendingWithdraw', data[5])[0].toString() / Math.pow(10, 18),
        depositehistory: stakeItf.decodeFunctionResult('getUserDeposite', data[6])[0],
        nextRewardTime: stakeItf.decodeFunctionResult('getNextRewardTime', data[7])[0].toString() * 1000

      })
    }

    if (mc && account && chainId === CHAIN_ID) {
      fetch();
    }
    else {
      setStats({
        balance: 0,
        rewardEarned: 0,
        pendingRewards: 0,
        totalStake: 0,
        isApprove: 0,
        pendingWithdraw: 0,
        depositehistory: [],
        nextRewardTime: 0

      })
    }

  }, [mc, account, updater, chainId]);

  return stats;
}
