import { ethers } from "ethers";
import { simpleRpcProvider ,  RPC_URLS , CHAIN_ID, bscRpcProvider } from "./connectors";
import multicallAbi from '../json/multicall.json';
import { bscMultiCall, multicallAddress , multicallAddressv2 } from "./constant";
import Multicall from '@dopex-io/web3-multicall';


export const getContract =  (abi, address, library = undefined) => {
  const signerOrProvider = library ? library.getSigner() : simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getContractBsc =  (abi, address, library = undefined) => {
  const signerOrProvider = library ? library.getSigner() : bscRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const MulticallContractWeb3 = (chainId) => {
  const multicall = new Multicall({
    multicallAddressv2,
    provider : RPC_URLS[chainId ? chainId : CHAIN_ID]
  });

  return multicall;

}

export const getMulticallContract = (chainId) => {
  if(chainId === 56){
    return getContractBsc(multicallAbi,  bscMultiCall);
  }else{
    return getContract(multicallAbi, multicallAddressv2);
  }
  
}

export const formatPrice = (num) => {
  return new Intl.NumberFormat('en-US').format(num);
}


